@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-250 {
    animation-delay: -750ms;
  }
  .animation-delay-500 {
    animation-delay: -500ms;
  }
  .animation-delay-750 {
    animation-delay: -250ms;
  }
}
